import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ProductList from './components/ProductList'; // Adjust the path if necessary
import ProductDetail from './components/ProductDetail';
import Header from './components/Header'; // Import the Header component
import Cart from './components/Cart'; // Import the Cart component
import { CartProvider } from './contexts/CartContext'; // Import the CartProvider
import './App.css';
import Checkout from './components/Checkout'; // Import the Checkout component
import OrderConfirmation from './components/OrderConfirmation'; // Adjust the path if necessary
import Register from './components/Register';
import Login from './components/Login';










function App() {
	return (
		<CartProvider>
			<Router>
				<div className="App">
					<header className="App-header">
						<Header /> {/* Include the Header component */}
					</header>
					<main>
						<Routes>
							<Route path="/" element={<ProductList />} />
							<Route path="/product/:id" element={<ProductDetail />} />
							<Route path="/cart" element={<Cart />} /> {/* Add the Cart route */}
							<Route path="/cart" component={Cart} />
							<Route path="/checkout" element={<Checkout />} /> {/* Add Checkout route */}
							<Route path="/order-confirmation" element={<OrderConfirmation />} /> {/* Add Order Confirmation route */}
							<Route path="/register" component={Register} />
							<Route path="/login" component={Login} />

						</Routes>
					</main>
				</div>
			</Router>
		</CartProvider>
	);
}

export default App;
